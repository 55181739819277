.card {
  height: 100%
}

.ChartContainer {
  position: relative;
  margin-top: 40px;
  width: 100%;
  height: auto;
}

.chart_container {
  position: relative;
  margin: auto;
  width: 80%;
  height: auto;
}


.table td {
  max-width: 100px;
  min-width: 50px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.table td:first-child {
  text-align: left!important;
}

.span {
  padding-left: 3px
}