.compute-tab .nav-tabs .nav-link {
  border-bottom: 2px solid #E5E5E5;
  color: #000;
}

.compute-tab .nav-link.active {
  color: #20C2AF;
  background-color: #fff;
  border-color: transparent transparent #20C2AF;
  font-weight: bold;
}

.compute-tab .table>:not(:first-child) {
  border-top: 2px solid #20C2AF;
}

.compute-tab th {
  font-weight: 500;
}

.a {
  color: #20C2AF;
  font-size: 12px;
  font-family: monospace;
  text-decoration: none;
}

.a:hover {
  color: #1aa179;
  text-decoration: underline;
}

.mc:hover {
  background-color: rgba(227, 246, 242, 1);
}

.modal-st .modal-header {
  border-bottom: 2px solid #20C2AF;
}

.modal-st .modal-footer {
  background-color: rgba(0,0,0,.03);
  border-top: 1px solid rgba(0,0,0,.125);
}