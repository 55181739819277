.signup .input-group {
  height: calc(3.5rem + 2px);
}

.signup ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

.signup :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}

.signup ::-ms-input-placeholder { /* Microsoft Edge */
  color: black;
}