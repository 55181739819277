.ActiveHighlight {
    font-weight: 600;
}

/* center icons vertically */
.BI {
    vertical-align: 0em;
    width: 1em;
    height: 1em;
}

.ButtonGroupSmall>.Button, .ButtonSmall {
    padding: .25rem 1.00rem;
    font-size: .875rem;
    border-radius: .2rem;
}

.ButtonOutlineWerender {
    color: #20c997;
    background-color: transparent;
    border-color: #20c997;
}

.ButtonOutlineWerender:hover {
    color: #FFF;
    background-color: #1aa179;
    border-color: #1aa179;
}

.ButtonWerender {
    color: #FFF;
    background-color: #20c997;
    border-color: #20c997;
}

.ButtonWerender:hover {
    color: #FFF;
    background-color: #1aa179;
    border-color: #1aa179;
}

.NavbarColorBar {
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-image: -webkit-linear-gradient(linear, left, #725CA7, #5F4B8B);
    background-image: linear-gradient(to left, rgba(32, 201, 151, 0.75), #1aa179);
    z-index: 2;
}

.HeaderWerender {
    background-color: rgba(255, 255, 255, .90) !important;
    -webkit-backdrop-filter: saturate(50%) blur(20px);
    backdrop-filter: saturate(360%) blur(20px);
}