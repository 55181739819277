.overview {
  max-width: 50%;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 1280px) {
  .overview {
    max-width: 100%;
  }
}
