.account {
  max-width: 50%;
  margin-right: auto;
  margin-left: auto;
}

.account .btn-wr {
  color: #FFF;
  background-color: #20c997;
  border-color: #20c997;
}

.account .btn-wr:hover {
  color: #FFF;
  background-color: #1aa179;
  border-color: #1aa179;
}

.account .btn-group-sm>.btn, .btn-sm {
  padding: .25rem 1.00rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.transaction-table .nav-tabs .nav-link {
  border-bottom: 2px solid #E5E5E5;
  color: #000;
}

.transaction-table .nav-link.active {
  color: #20C2AF;
  background-color: #fff;
  border-color: transparent transparent #20C2AF;
  font-weight: bold;
}

.transaction-table .table>:not(:first-child) {
  border-top: 2px solid #20C2AF;
}

.transaction-table th {
  font-weight: 500;
}

@media screen and (max-width: 1280px) {
  .account {
    max-width: 100%;
  }
}