.BackgroundMasthead {
  background: linear-gradient(170deg, #d2f4ea 50%, #fff 60%) no-repeat;
  background-size: cover;
}

.ButtonGroupSmall>.Button, .ButtonSmall {
  padding: .25rem 1.00rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.ButtonWerender {
  color: #FFF;
  background-color: #20c997;
  border-color: #20c997;
}

.ButtonWerender:hover {
  color: #FFF;
  background-color: #1aa179;
  border-color: #1aa179;
}