.BackgroundTop {
    background-color: #d2f4ea;
}

.BackgroundWhite {
    background-color: #ffffff;
}

.BI {
    vertical-align: -.125em;
    pointer-events: none;
    fill: currentColor;
}

.BI {
    vertical-align: 0em;
    width: 1em;
    height: 1em;
}

.ButtonCheck:focus+.ButtonOutlineDark, .ButtonOutlineDark:focus {
    box-shadow: 0 0 0 0rem transparent;
}

.ButtonGroupSmall>.Button, .ButtonSmall {
    padding: .25rem 1.00rem;
    font-size: .875rem;
    border-radius: .2rem;
}

.Card {
    border: 0px;
    height: 100%;
}

.LineHeight15 {
    line-height: 1.5rem;
}

.NavLinkTab {
    color: #20c997;
}

.NavLinkTab:hover {
    color: #1aa179;
}

.Subtitle {
    font-size: 16px;
    color: rgb(53, 57, 61) !important;
}

.ThinColorBar {
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-image: -webkit-linear-gradient(linear, left, #725CA7, #5F4B8B);
    background-image: linear-gradient(to left, rgba(32, 201, 151, 0.5), rgba(26, 161, 121, 0.5));
    z-index: 2;
}