.reset-password {
  background: linear-gradient(170deg, #d2f4ea 50%, #fff 60%) no-repeat;
  background-size: cover;
}

.reset-password .btn-wr {
  color: #FFF;
  background-color: #20c997;
  border-color: #20c997;
}

.reset-password .btn-group-sm>.btn, .btn-sm {
  padding: .25rem 1.00rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.reset-password .btn-wr:hover {
  color: #FFF;
  background-color: #1aa179;
  border-color: #1aa179;
}

.reset-password .margin-2 {
  margin-top: 2% !important;
}