.BI {
    vertical-align: -.125em;
    pointer-events: none;
    fill: currentColor;
}

.BI {
    vertical-align: 0em;
    width: 1em;
    height: 1em;
}

.ButtonWerender {
    color: #FFF;
    background-color: #20c997;
    border-color: #20c997;
}

.ButtonWerender:hover {
    color: #FFF;
    background-color: #1aa179;
    border-color: #1aa179;
}

.LineHeight15 {
    line-height: 1.5rem;
}

.Subtitle {
    font-size: 16px;
    color: rgb(53, 57, 61) !important;
}

