.btn-wr {
  color: #FFF !important;
  background-color: #20c997 !important;
  border-color: #20c997 !important;
}

.btn-wr:hover {
  color: #FFF;
  background-color: #1aa179;
  border-color: #1aa179;
}

.btn-group-sm>.btn, .btn-sm {
  padding: .25rem 1.00rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

