.BackgroundLight {
    background-color: #f6f9fc;
}

.BackgroundTop {
    background-color: #d2f4ea;
}

.BI {
    vertical-align: -.125em;
    pointer-events: none;
    fill: currentColor;
}

.BI {
    vertical-align: 0em;
    width: 1em;
    height: 1em;
}

.BorderTopCard {
    border-top: 4px solid !important;
}

.BorderWerender:hover {
    border-color: #20c997;
}

.ButtonWerender {
    color: #FFF;
    background-color: #20c997;
    border-color: #20c997;
}

.ButtonWerender:hover {
    color: #FFF;
    background-color: #1aa179;
    border-color: #1aa179;
}

.ButtonGroupSmall>.Button, .ButtonSmall {
    padding: .25rem 1.00rem;
    font-size: .875rem;
    border-radius: .2rem;
}

.Card {
    border: 0px;
    height: 100%;
}

.CardFooterPlans {
    padding-bottom: 2rem;
    background-color: transparent;
    border: none;
}

.CarouselCaption {
    top: 0;
    bottom: auto;
}

.CarouselInner {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity));
    text-align: center;
}

.CarouselItem {
    height: 35vh;
    object-fit: scale-down;
}

.LineHeight15 {
    line-height: 1.5rem;
}

.Subtitle {
    font-size: 16px;
    color: rgb(53, 57, 61) !important;
}

.TextWerender {
    color: #20c997;
}

.ThinColorBar {
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-image: -webkit-linear-gradient(linear, left, #725CA7, #5F4B8B);
    background-image: linear-gradient(to left, rgba(32, 201, 151, 0.5), rgba(26, 161, 121, 0.5));
    z-index: 2;
}
