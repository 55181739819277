.a {
    color: #20C2AF;
    font-size: 12px;
    font-family: monospace;
    text-decoration: none;
}

.a:hover {
    color: #1aa179;
    text-decoration: underline;
}

.a-shared-link {
    color: #20C2AF;
    text-decoration: none;
}

.a-shared-link:hover {
    color: #1aa179;
    text-decoration: underline;
}

.border-none {
    border: none;
}

.breadcrumb > ol {
    background-color: white;
}

.breadcrumb > ol > li {
    color: #20C2AF;
}

.breadcrumb-item li:first-child::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: ">";
}

.btn-outline {
    color: #20C2AF;
    background-color: transparent;
    border-color: #20C2AF;
}

.btn-outline:hover {
    color: #FFF;
    background-color: #1aa179;
    border-color: #1aa179;
}

.btn-wr {
    color: #FFFFFF;
    background-color: #20c997;
    border-color: #20c997;
}

.btn-group-sm>.btn, .btn-sm {
    padding: .25rem 1.00rem;
    font-size: .875rem;
    border-radius: .2rem;
}

.react-bootstrap-table {
    height: 500px;
    overflow-y: auto;
}

.btn-wr:hover {
    color: #FFFFFF;
    background-color: #1aa179;
    border-color: #1aa179;
}

.i {
    transition: transform .2s;
}

.i:hover {
    /*-ms-transform: scale(2);*/
    /*-webkit-transform: scale(2);*/
    /*transform: scale(2);*/
}

.m .modal-content {
    background-color: transparent;
    border: 0;
}

.mc:hover {
    background-color: rgba(227, 246, 242, 1);
}

.storage .nav-tabs .nav-link {
    border-bottom: 2px solid #E5E5E5;
    color: #000;
}

.storage .nav-link.active {
    color: #20C2AF;
    background-color: #fff;
    border-color: transparent transparent #20C2AF;
    font-weight: bold;
}

.storage .table>:not(:first-child) {
    border-top: 2px solid #20C2AF;
}

.storage th {
    font-weight: 500;
}

.upload input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
}

.tree {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #FBFBFB;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05)
}
.tree li {
    list-style-type: none;
    margin: 0;
    padding: 10px 5px 0 5px;
    position: relative
}

.tree li::before, .tree li::after {
    content: '';
    left: -20px;
    position: absolute;
    right: auto
}

.tree li::before {
    border-left: 1px solid #999;
    bottom: 50px;
    height: 100%;
    top: 0;
    width: 1px
}

.tree li::after {
    border-top: 1px solid #999;
    height: 20px;
    top: 30px;
    width: 25px
}

.tree li span {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border: 1px solid #999;
    border-radius: 5px;
    display: inline-block;
    padding: 3px 8px;
    text-decoration: none
}

.tree li.parent_li>span {
    cursor: pointer
}

.tree>ul>li::before, .tree>ul>li::after {
    border: 0
}

.tree li:last-child::before {
    height: 30px
}

.tree li.parent_li>span:hover, .tree li.parent_li>span:hover+ul li span {
    background: #EEEEEE;
    border: 1px solid #94A0B4;
    color: #000000
}