.ComputeCreditWidget {}

.Card {
  height: 100%
}

.ChartContainer {
  position: relative;
  margin-top: 40px;
  width: 100%;
  height: auto;
}