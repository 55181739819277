.BackgroundDark{
  background-color: cyan;
}

.BackgroundLight{
  background-color: #f6f9fc;
}

.BackgroundTop{
  background-color: #d2f4ea;
}

.BackgroundWhite{
  background-color: #ffffff;
}

.BackgroundLiteGreen{
  background-color: #e7f1ee;
}

.BI {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.BI {
  vertical-align: 0em;
  width: 1em;
  height: 1em;
}

.ButtonCard {
  border-top: 4px solid !important;
}

.ButtonCheck:focus+.ButtonOutlineDark, .ButtonOutlineDark:focus {
  box-shadow: 0 0 0 0rem transparent;
}

.ButtonGroupSmall>.Button, .ButtonSmall {
  padding: .25rem 1.00rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.ButtonOutlineWerender {
  color: #20c997;
  background-color: transparent;
  border-color: #20c997;
}

.ButtonOutlineWerender:hover {
  color: #FFF;
  background-color: #1aa179;
  border-color: #1aa179;
}

.ButtonWerender {
  color: #FFF;
  background-color: #20c997;
  border-color: #20c997;
}

.ButtonWerender:hover {
  color: #FFF;
  background-color: #1aa179;
  border-color: #1aa179;
}

.Card {
  border: 0px;
  height: 100%;
}

.CardFooter {
  padding: .5rem 1rem;
  background-color: rgba(0,0,0,.03);
  border-top: 1px solid rgba(0,0,0,.125);
}

.LineHeight {
  line-height: 1.5rem;
}

.MarketFontSize {
  font-size: 1.5rem;
}

.ModalHeader {
  position: relative;
}

.NavItem{
  padding-right: 2em;
}

.OverflowHidden {
  max-height: 30vh;
}

.Subtitle{
  font-size: 16px;
  color: rgb(53, 57, 61) !important;
}

.ThinColorBar {
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-image: -webkit-linear-gradient(linear, left, #725CA7, #5F4B8B);
  background-image: linear-gradient(to left, rgba(32, 201, 151, 0.5), rgba(26, 161, 121, 0.5));
  z-index: 2;
}

.WerenderText {
  color: rgba(12, 181, 131);
}

.VH-45 {
  height: 45vh !important;
}