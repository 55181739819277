.text-small {
	font-size: small;
}

.text-medium {
	font-size: medium;
}

.text-color-gray {
	color: gray;
}

.content-center {
	justify-content: center;
}

.image-text-centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
