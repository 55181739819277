.bg-light{
  background-color: #f6f9fc;
}

.no-border {
  border: 0px;
  height: 100%;
}

.text-warp {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-break {
  word-wrap: break-word;
}

.background-masthead {
  background: linear-gradient(170deg, #d2f4ea 50%, #fff 60%) no-repeat;
  background-size: cover;
}

.custom-link-table {
  color: #20C2AF;
  font-size: 12px;
  font-family: monospace;
  text-decoration: none;
}

.custom-link-table:hover {
  color: #1aa179;
  text-decoration: underline;
}

.mc:hover {
  background-color: rgba(227, 246, 242, 1);
}