.use-case .ml-p-1 {
  margin-left: 1% !important;
}

.industry .card-img-top-h-250-px {
  height: 250px !important;
  text-align: center;
}

.industry .card-img-ratio {
  height: 100%;
  object-fit: scale-down;
}

.feature .ml-p-1 {
  margin-left: 1% !important;
}

.industry .ml-p-1 {
  margin-left: 1% !important;
}