.pricing .min-w-25 {
  min-width: 25% !important;
}

.pricing .min-w-50 {
  min-width: 50% !important;
}

.vh-45 {
  height: 45vh !important;
}

.text-bold {
  font-weight: bold;
}

.text-decoration-none {
  text-decoration: none;
}

.text-normal {
  font-weight: normal;
}

.padding-unset {
  padding: unset;
}