.ox-hidden {
    overflow-x: hidden;
}

.v-hidden {
    visibility: hidden;
}

.dashboard {
    height: 100%;
    display: flex;
    position: relative;
}

.dashboard .large-color-bar {
    height: 2px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #20C2AF;
}

.dashboard .thin-color-bar {
    height: 1px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #20C2AF;
}

.dashboard .navbar-color-bar {
    height: 2px;
    background-color: #20C2AF;
    animation: blinker 1s linear;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.dashboard .pro-sidebar {
    height: 100vh;
}

.dashboard .pro-sidebar-header {
    font-size: 20px;
    margin-top: auto;
    background-color: rgba(227, 246, 242, 1) !important;
    -webkit-backdrop-filter: saturate(50%) blur(20px);
    backdrop-filter: saturate(360%) blur(20px);
    color: #000;
    border-color: transparent !important;
    padding: 20px 24px;
}

.dashboard .pro-sidebar-content {
    background-color: rgba(227, 246, 242, 1) !important;
    border-color: transparent !important;
}

.dashboard .pro-sidebar-footer {
    background-color: rgba(227, 246, 242, 1) !important;
    border-color: transparent !important;
}

.dashboard .pro-sidebar-inner {
    background-color: transparent;
    box-shadow: rgba(0,0,0,.03);
}

.dashboard .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #20c997;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
}

.dashboard main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.footer-info-icon {
    width: 30px !important;
    height: 30px !important;
    color: #000;
    background-color: transparent !important;
}

.dashboard .pro-icon-wrapper {
    color: #000;
    background-color: transparent !important;
    border-color: transparent;
    font-size: 20px !important;
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
}

.dashboard .pro-item-content {
    color: #000;
}

.dashboard .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #20C2AF1F 50%, #20C2AF1F 100%);
}

@media screen and (max-width: 768px) {
    .btn-toggle {
        display: flex !important;
    }
    .pro-sidebar {
        height: 100vh !important;
    }
    .ox-hidden {
        overflow-x: auto;
    }
}

@media screen and (min-width: 769px) {
    .pro-sidebar {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
}

.dropdown-no-caret .dropdown-toggle::after {
    display: none;
}

.navbar-trans-button .dropdown-menu[data-bs-popper] {
    right: 5px;
    left: auto;
}

.navbar-user-button {
    width: 30px !important;
    height: 30px !important;
}

.dropdown .dropdown-menu {
    min-width: max-content;
}

.dashboard-navbar {
    position: sticky;
    top: 0;
    z-index: 1;
}

.dashboard-layout {
    padding: 4px 40px 24px;
    width: 100%;
}

.loading-bar {
    background: rgba(227, 246, 242, 1);
    width: 100%;
    height: 2px;
}


.loading-bar::before {
    background: #20C2AF;
    content: "";
    position: absolute;
    left: 0;
    height: 2px;
    animation-name: animate;
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-direction: normal;
}

@keyframes animate {
    0% {
        width: 0;
    }
    100% {
        width: 95%;
    }
}

.navbar {
    background-color: rgba(255, 255, 255, .90) !important;
    -webkit-backdrop-filter: saturate(50%) blur(20px);
    backdrop-filter: saturate(360%) blur(20px);
}